/* ConsultationForm.css */

/* Customize Card Component */
.card {
  border-radius: 15px;
  background-color: #ffffff;
}

/* Customize Form Labels */
.form-label {
  font-weight: 600;
  color: #343a40;
}

/* Customize Submit and Navigation Buttons */
.btn-primary,
.btn-success,
.btn-secondary {
  background-color: #007bff;
  border-color: #007bff;
  transition: background-color 0.3s ease;
}

.btn-primary:hover,
.btn-success:hover,
.btn-secondary:hover {
  background-color: #0056b3;
  border-color: #004085;
}

/* Disabled Button Styles */
.btn-primary:disabled,
.btn-success:disabled,
.btn-secondary:disabled {
  background-color: #6c757d;
  border-color: #6c757d;
  opacity: 1;
  cursor: not-allowed;
  color: #ffffff;
}

/* Add Spinner Animation */
.spinner-border {
  width: 1rem;
  height: 1rem;
}

/* Form Control Focus State */
.form-control:focus {
  border-color: #007bff;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

/* Progress Bar Customization */
.progress-bar {
  font-size: 0.9rem;
  font-weight: 500;
}

/* Responsive Adjustments */
@media (max-width: 576px) {
  .card-body {
    padding: 1rem;
  }
}

.calendly-step {
  text-align: center;
  padding: 20px;
}
